import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faWindow,
  faLockAlt,
  faMousePointer,
  faImage,
} from "@fortawesome/pro-duotone-svg-icons"

export default function ApplicationIcon({ icon }) {
  switch (icon) {
    case "Public web applications":
      return (
        <FontAwesomeIcon
          size="1x"
          className="text-purple-600"
          icon={faWindow}
        />
      )

    case "Private web applications":
      return (
        <FontAwesomeIcon
          size="1x"
          className="text-purple-600"
          icon={faLockAlt}
        />
      )

    case "Self-service analytics platform":
      return (
        <FontAwesomeIcon
          size="1x"
          className="text-purple-600"
          icon={faMousePointer}
        />
      )

    case "Custom visualization design and development":
    default:
      return (
        <FontAwesomeIcon size="1x" className="text-purple-600" icon={faImage} />
      )
  }
}
